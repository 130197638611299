@import 'src/styles/variables';

.base {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $color-attention0;
  margin-top: 80px;

  @media (min-width: $bp2) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.isotipo {
  transform: translateY(-32px);

  @media (min-width: $bp2) {
    transform: none;
    margin-top: 54px;
    margin-left: 5%;
    align-self: flex-start;
  }
}

.copyright {
  text-align: center;
  margin-top: 32px;
  color: $color-gray3;
  line-height: 2;

  @media (min-width: $bp2) {
    display: none;
  }
}
