@import 'src/styles/variables';

.header {
  @media (min-width: $bp2) {
    position: sticky;
    top: 0;
    background-color: $color-white;
    z-index: 10;
  }
}

.inner {
  position: relative;
  min-height: 100px;

  @media (min-width: $bp2) {
    width: calc(100vw - 80px);
    margin: 0 auto;
    padding: 0;
    display: flex;
    justify-content: space-between;
  }

  @media (min-width: 1280px) {
    width: 1200px;
  }
}
