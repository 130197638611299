@import 'src/styles/variables';

.container {
  display: flex;
  align-items: center;

  @media (min-width: $bp2) {
    flex-direction: column;
    background-color: $color-white0;
    align-items: flex-start;
    order: 3;
    width: 50%;
    align-self: stretch;
    background-image: url('/images/footerBG.jpg');
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 54px 0 54px 20px;
  }
}

.links {
  display: flex;
}

.link {
  width: 48px;
  height: 48px;
  background-color: $color-gray4;
  border-radius: 50%;
  color: $color-dark;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;

  &:hover {
    text-decoration: none;
  }
}

.copyright {
  display: none;

  @media (min-width: $bp2) {
    display: block;
    margin-top: 75px;
    color: $color-gray3;
  }
}
