@import 'src/styles/variables';
@import 'src/styles/mixins';

.cartWrapper {
  background: $color-attention0;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  position: relative;
  overflow: auto;

  @media (min-width: $bp2) {
    padding: 40px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50%;
  }
}

.header {
  display: flex;
  width: 100%;
}

.title {
  font-weight: 700;
  white-space: nowrap;
  font-family: var(--poppins-font);
  align-self: flex-start;
}

.name {
  margin-left: 8px;
}

.quantity {
  margin-right: 8px;
  font-weight: 700;
}

.price {
  font-weight: 700;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 32px 0;
  align-self: flex-start;
}

.line {
  // margin-right: 16px;
}

.item {
  line-height: 1.3;
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 8px 32px 8px 0;
  border-radius: 4px;
  position: relative;

  :not(:first-of-type) {
    margin-top: 10px;
  }
}

.removeLineItem {
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  svg {
    transform: translateY(3px);
  }
}

.close {
  @include iconButton(40px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 16px;
  right: 16px;

  @media (min-width: $bp2) {
    top: 32px;
    right: 32px;
  }
}

.overlay {
  &[data-reach-dialog-overlay] {
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: $bp1) {
      overflow: hidden;
    }
  }
}

.overlayContent {
  @include baseGrid;

  &[data-reach-dialog-content] {
    background: transparent;
    padding: 0;

    @media (min-width: $bp1) {
      position: relative;
      height: 90vh;
    }
  }
}

.checkoutLink {
  width: 100%;

  @media (min-width: $bp2) {
    width: fit-content;
    align-self: flex-end;
    margin-top: 32px;
  }
}

.closeIcon {
  transform: translateY(2px);
}
