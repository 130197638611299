@import 'src/styles/mixins';

.linkInline {
  @include linkInline;
}

.askForNewLink {
  @extend .linkInline;
  margin-left: 10px;
}

.signupIntro {
  transform: translateY(-20px);
  @media (min-width: $bp2) {
    transform: translateY(-30px);
  }
}

.formContainer {
  max-width: 620px;
}

.forgotPassword {
  margin-top: 30px;
}
