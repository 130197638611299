@import 'src/styles/variables';

.container {
  @media (min-width: $bp2) {
    display: flex;
    align-self: flex-start;
    margin-top: 54px;
  }
}

.group {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;

  @media (min-width: $bp2) {
    margin: 0 80px 0 0;
    align-items: flex-start;
  }
}

.title {
  text-transform: uppercase;
  color: $color-dark;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 8px;
}

.link {
  text-decoration: none;
  font-size: 18px;
  color: $color-dark;
  font-weight: 700;
  line-height: 24px;

  &:hover {
    text-decoration: underline;
  }
}
